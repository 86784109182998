<template>
  <div style="padding-bottom: 200px">
    <RocketLoad
      :full="true"
      v-if="isLoading"
    />

    <template>
      <div class="grid align-items-center justify-content-between">
        <div class="flex pb-3">
          <h1><template>Payment Detail</template></h1>
        </div>
        <div class="flex pb-3 justify-content-end align-items-center"></div>
      </div>
      <Divider />
      <form
        @submit.prevent="submitHandler"
        v-form-enter
      >
        <div class="p-fluid formgrid grid">
          <div class="field col-12 sm:col-6">
            <label class="label">Amount </label>
            <div class="p-inputgroup">
              <InputNumber
                placeholder="Amount"
                mode="decimal"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label class="label">Match to Invoice</label>
            <div class="p-inputgroup">
              <Dropdown placeholder="Match to Invoice" />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label class="label">Payment Date </label>
            <Calendar
              placeholder="Payment Date"
              id="date"
              :showIcon="true"
              dateFormat="yy-mm-dd"
            />
          </div>
        </div>
        <div class="flex justify-content-between">
          <Button
            type="submit"
            label="Add Payment"
            :input-class="['is-primary']"
          />
          <Button class="p-button-text p-button-secondary">
            CANCEL
          </Button>
        </div>
      </form>
    </template>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "PaymentSlider",
  components: {},
  data() {
    return {
      submitted: false,
      isLoading: false,
    };
  },
  validations: {},
  mounted() {},
  methods: {
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        this.$axios
          .post(process.env.VUE_APP_ROOT_API + "", JSON.stringify(), {
            headers: { "Content-type": "application/json" },
          })
          .then((response) => {
            this.isLoading = false;
            this.clearSliderContent();
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
  },
};
</script>